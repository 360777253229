<template>
  <div
    v-if="taskObject.id"
    class="task-cell"
    :class="{
      'task-cell_first': order !== 0
    }"
  >
    <div v-if="order !== 0" class="action action_show" @click="clearTask"></div>
    <span :title="taskObject.name">
      <a :href="taskObject.url" target="_blank" class="task-cell__link">
        {{ taskObject.name }}
      </a>
    </span>
    <div v-if="taskObject.list_name" class="task-cell__list" :title="taskObject.list_name">Task list: {{ taskObject.list_name }}</div>
    <button v-if="isLast" @click="addTask"></button>
  </div>
  <div v-else class="task-cell" :class="{ 'task-cell_first': order !== 0 }">
    <v-select
      :options="options"
      v-model="selected"
      @input="setSelected"
      placeholder="Please choose the task"
    >
      <template #option="{ label, listName }">
        <div>{{ label }}</div>
        <span class="task-cell__list" style="margin-top: 0" v-if="listName">{{ listName }}</span>
      </template>
    </v-select>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapMutations, mapState, mapGetters } from 'vuex'
import mtApi from '../../agGridV2/helpers/mt-api.helper'
export default {
  name: 'TaskCell',
  components: {
    vSelect
  },
  props: {
    task: {
      type: Object
    },
    value: {
      type: Object
    },
    order: Number,
    isNew: Boolean,
    isLast: Boolean
  },
  data() {
    return {
      options: [],
      selected: [],
      tasks: [],
      taskObject: {}
    }
  },
  computed: {
    ...mapState({
      statuses: (state) => state.weeklyLog.statuses
    }),
    ...mapGetters(['daysWeek', 'allTasks', 'folder'])
  },
  async mounted() {
    this.taskObject = this.task
    if (!this.task.id) {
      this.setViewLoader(true)

      try {
        let response = await mtApi.getTasksByProject({
          id: this.task.folder_id
        })
        // let response = await Api.clickUp.fetchTasksByProject({
        //   ids: [this.task.folder_id]
        // })

        this.tasks = response

        const existedTasks = this.folder(this.task.folder_id).tasks.map(task => task.id)
        const neededTask = response.filter(task => !existedTasks.includes(task.id))

        this.options = neededTask.map((task) => {
          return {
            label: task.name,
            code: task.id,
            url: task.url,
            listName: task.list_name
          }
        })
      } finally {
        this.setViewLoader(false)
      }
    }
  },
  methods: {
    ...mapMutations(['addTaskToFolder', 'setViewLoader', 'clearTaskRow']),
    setSelected(value) {
      this.taskObject.status = this.statuses.NEW
      this.taskObject.id = value.code
      this.taskObject.name = value.label
      this.taskObject.url = value.url
      this.taskObject.list_name = this.getListName(this.taskObject.id)
      this.taskObject.time_entries = this.daysWeek.map((day) => {
        return {
          duration: null,
          initDuration: null,
          ids: [],
          status: this.statuses.INITIAL,
          at: day
        }
      })
    },
    getListName(id) {
      const task = this.tasks.find(item => item.id === id)

      if (task)
        return task.list_name

      return null
    },
    addTask() {
      if (this.isLast) {
        this.addTaskToFolder({
          status: this.statuses.NEW,
          folder_name: this.taskObject.folder_name,
          folder_id: this.taskObject.folder_id,
          time_entries: this.daysWeek.map((day) => {
            return {
              duration: null,
              initDuration: null,
              ids: [],
              status: this.statuses.INITIAL,
              at: day
            }
          })
        })
      }
    },
    clearTask() {
      this.clearTaskRow({ taskId: this.taskObject.id, allTasks: this.allTasks })
    }
  }
}
</script>

<style lang="scss" scoped>
.action {
  position: absolute;
  top: 50%;
  left: -14px;
  height: 16px;
  width: 16px;
  // visibility: hidden;
  flex-basis: 30px;
  background: url('../../assets/icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  margin-right: 4px;
  transform: translate(-50%, -50%);

  &_show {
    visibility: visible;
  }
}
.task-cell {
  flex-grow: 1;
  background: #fff;
  border: 1px solid $color-secondary;
  max-width: 100%;
  padding: 5px 10px;
  outline: none;
  border-right: unset;
  font-weight: normal;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    &::before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 2px;
      background-color: $color-primary;
      margin-left: -5px;
      margin-top: -1px;
    }
    &:after {
      transform: rotate(90deg);
    }
  }
  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 40px;
  }

  &_first {
    border-radius: 6px 0 0 6px;
  }
  &_new {
    background: $active-cell;
  }
}

.task-cell__list {
  font-size: 12px;
  opacity: 0.5;
  margin-top: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task-cell__link {
  transition: 0.2s ease-in-out;
}
</style>
