<template>
  <div class="report">
    <div class="header">Report {{ reportDate }} </div>
    <div class="all-time">Total Time {{ totalReport.string }}</div>
    <table>
      <thead>
        <tr>
          <td>Project</td>
          <td>Task</td>
          <td>General Status<img src="../assets/icons/pen.svg" /></td>
          <td>IN Status<img src="../assets/icons/pen.svg" /></td>
          <td>% Done<img src="../assets/icons/pen.svg" /></td>
          <td>Time</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="task in reportTaskList" :key="task.id">
          <td>
            <report-cell
              :type="'base'"
              :value="task.folder_name"
              :order="0"
            />
          </td>
          <td>
            <a :href="task.url" target="_blank" class="task-link">
              <report-cell :type="'base'" :value="task.name" :order="1" />
            </a>
          </td>
          <td>
            <report-cell
              :type="'general-status'"
              :general-statuses="generalStatuses"
              :value="task.general_status.status"
              :init-value="task.initial.general_status.status"
              :task-id="task.id"
              :updated="task.general_status.updated_status === 'new'"
            />
          </td>
          <td>
            <report-cell
              :type="'status'"
              :task-statuses="newTaskStatuses"
              :value="task.status.new_value"
              :init-value="task.status.new_value"
              :task-id="task.id"
              :updated="task.status.status === 'new'"
            />
          </td>
          <td>
            <report-cell
              :type="'progress'"
              :value="task.complete.value.string"
              :init-value="task.initial.complete.string"
              :task-id="task.id"
              :updated="task.complete.status === 'new'"
            />
          </td>
          <td>
            <report-cell
              :type="'base'"
              :value="task.time.string"
              :is-last="true"
              :task-id="task.id"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="actions">
      <button class="button button-primary" @click="sendReport">
        Send to Slack Channel
      </button>
      <span>Report will be sent to the channel</span>
    </div>
    <popup-template v-if="showConfirmPopup" @closePopup="handleClosePopup">
      <popup-confirm-report :text="popupText" @confirm="handleConfirmReport" />
    </popup-template>
  </div>
</template>

<script>
import ReportCell from './ReportCell.vue'
import { mapGetters, mapMutations } from 'vuex'
import { showSnackbar } from '@/services'
import PopupConfirmReport from './PopupConfirmReport.vue'
import PopupTemplate from './PopupTemplate.vue'
import mtApi from '../agGridV2/helpers/mt-api.helper'

export default {
  name: 'TheReport',
  components: {
    ReportCell,
    PopupConfirmReport,
    PopupTemplate
  },
  data() {
    return {
      showConfirmPopup: false
    }
  },
  computed: {
    ...mapGetters([
      'reportTaskList',
      'taskStatuses',
      'newTaskStatuses',
      'generalStatuses',
      'timeReportRequestBody',
      'totalReport',
      'timeReportUpdatedData',
      'reportMode',
      'reportDate'
    ]),
    popupText() {
      const total = this.totalReport.number
      const isLowComplete = this.reportTaskList.filter(task => Number(task.complete.value.current < 5))
      const conditions = [
        total < 126000000 && this.reportMode === 'weekly',
        total < 25200000 && this.reportMode === 'daily',
        isLowComplete.length
      ]
      for (let condition of conditions) {
        if (condition) {
          return 'Please make sure your daily log hours are around 8h and your task progress is correct.'
        }
      }
      return 'Are you sure you want to send a report?'
    }
  },
  methods: {
    ...mapMutations(['resetReportTaskList', 'setViewLoader']),
    handleClosePopup() {
      this.showConfirmPopup = false
    },
    async handleConfirmReport(data) {
      if (!data) {
        this.showConfirmPopup = false
        return null
      }
      if (this.timeReportUpdatedData.length) {
        this.setViewLoader(true)
        this.timeReportUpdatedData.forEach(async (task) => {
          console.log('⭕️this.timeReportUpdatedData')
          // try {
          //   await Api.clickUp.updateTask({ task })
          //
          //   this.resetReportTaskList()
          //
          //   showSnackbar({
          //     mode: 'success',
          //     text: 'Task parameters were updated successfully!'
          //   })
          // } finally {
          //   this.setViewLoader(false)
          // }
        })
      }
      this.setViewLoader(true)
      const reportBody = this.timeReportRequestBody
      reportBody.mode = this.reportMode
      reportBody.total = this.totalReport.string
      reportBody.date = this.reportDate

      try {
        // await Api.privateApi.storeTimeReport(
        //   this.timeReportRequestBody
        // )
        await mtApi.sendReport({
          date: this.timeReportRequestBody.date,
          mode: this.timeReportRequestBody.mode,
          total: this.timeReportRequestBody.total,
          tasks: this.timeReportRequestBody.tasks
        })

        showSnackbar({
          mode: 'success',
          text: 'Report sent successfully!'
        })
      } finally {
        this.setViewLoader(false)
      }

      this.showConfirmPopup = false
    },
    sendReport() {
      this.showConfirmPopup = true
    }
  }
}
</script>

<style lang="scss" scoped>
.report {
  padding: 70px 0;
}
.header {
  font-size: 26px;
  text-align: left;
}
.all-time {
  font-weight: 500;
  font-size: 18px;
  text-align: right;
}
table {
  margin: 20px 0 50px;
  width: 100%;
  font-size: 16px;
  table-layout: fixed;
  border-collapse: collapse;

  thead {
    td {
      position: relative;
      border: 1px solid $color-secondary;
      border-bottom: 1px solid #a8a8a8;
      font-weight: 500;
      padding: 20px 0 20px 10px;

      img {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        margin-left: auto;
      }
    }
    td:nth-child(2) {
      width: 480px;
    }
    td:nth-child(3) {
      width: 200px;
    }
    td:nth-child(4) {
      width: 200px;
    }
    td:first-child {
      border-left: unset;
    }
    td:last-child {
      border-right: unset;
    }
  }

  tbody {
    background-color: $bg-secondary;
    border-bottom: 1px solid #a8a8a8;
    tr:first-child {
      td {
        padding-top: 20px;
      }
    }
    tr:last-child {
      td {
        padding-bottom: 20px;
      }
    }
    td {
      padding: 5px 0;
    }
    td:first-child {
      padding-left: 10px;
    }
    td:last-child {
      padding-right: 10px;
    }
  }
}
.actions {
  display: flex;
  align-items: center;

  span {
    margin-left: 1.5rem;
  }
}

.task-link {
  &:hover {
    ::v-deep {
      .report-cell input {
        color: #0a58ca;
      }
    }
  }
}
</style>
