const PercentValidator =  {
  convertToPercent(string) {
    const percentsMatch = string.match(/\d+/)
    if (percentsMatch) {
      const numbers = parseInt(percentsMatch[0])
      if (numbers >= 0 && numbers <= 100) {
        return `${numbers}%`
      }
    }
    return null
  }
}

export { PercentValidator }
