<template>
  <div class="datepicker">
    <div class="datepicker-control">
      <date-pick
        ref="datepicker"
        v-model="value"
        @input="handleInput"
        :inputAttributes="{ readonly: true }"
        :format="'MM/DD/YYYY'"
        :isDateDisabled="isFutureDate"
      ></date-pick>
      <img src="../../assets/icons/calendar.svg" alt="" />
    </div>
    <button
      class="button button-primary button-half-rounded"
      @click.stop="triggerDatepicker"
    >
      Select Week
    </button>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick'
import { parse, getDay, subDays, addDays, startOfDay } from 'date-fns'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'DatePicker',
  components: { DatePick },

  data() {
    return {
      range: {
        startDate: '',
        endDate: ''
      }
    }
  },
  mounted() {
    this.setupRange(startOfDay(new Date()))
  },
  computed: {
    ...mapGetters(['timeEntryRequestBody']),
    value: {
      get() {
        return this.$store.getters.weekValue
      },
      set(value) {
        this.$store.commit('setWeekValue', value)
      }
    }
  },
  methods: {
    ...mapMutations(['setWeek']),
    handleInput(e) {
      const currentDate = parse(e, 'MM/dd/yyyy', new Date())
      this.setupRange(currentDate)
    },
    getStartOfWeek(date) {
      let currentWeekDay = getDay(date)

      if (currentWeekDay === 0)
        currentWeekDay = 7

      return subDays(date, currentWeekDay - 1)
    },
    getEndOfWeek(date) {
      let currentWeekDay = getDay(date)

      if (currentWeekDay === 0)
        currentWeekDay = 7

      return addDays(date, 7 - currentWeekDay)
    },
    setupRange(currentDate) {
      const startOfWeek = this.getStartOfWeek(currentDate)
      const endOfWeek = this.getEndOfWeek(currentDate)
      this.range.startDate = startOfWeek
      this.range.endDate = addDays(endOfWeek, 1)
      // Check if you have not submitted changes
      if (this.checkNotSubmittedChanges()) {
        this.$emit('exist-changes', this.range)
        return false
      }
      // Need refact, it is not right
      this.setWeek({
        range: this.range,
        update: true
      })
    },
    triggerDatepicker() {
      this.$refs.datepicker.toggle()
    },
    isFutureDate(date) {
      return date > this.getEndOfWeek(new Date())
    },
    checkNotSubmittedChanges() {
      return this.timeEntryRequestBody.tasks.length
    }
  }
}
</script>

<style lang="scss">
.datepicker {
  display: inline-flex;
}
.datepicker-control {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 20px;
    height: auto;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.datepicker .vdpComponent.vdpWithInput > input {
  border-radius: 6px 0 0 6px;
}
</style>
