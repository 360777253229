<template>
  <div class="project-select">
    <v-select
      :options="foldersOptions"
      v-model="selectedValue"
      @input="setSelected"
      ref="selectProject"
    >
      <template v-slot:option="option">
        <span :class="option.status">
          {{ option.label }}
        </span>
      </template>
    </v-select>
    <img src="../../assets/icons/plus.svg" alt="" @click="openSelect"/>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProjectSelect',
  components: {
    vSelect
  },

  props: {
    initialOptions: {
      type: Array
    }
  },
  data() {
    return {
      options: [
        {
          label: '',
          code: '',
          status: ''
        }
      ],
      selectedValue: {
        code: '',
        label: 'Project'
      }
    }
  },
    computed: {
    ...mapGetters(['wantedFolders']),
    ...mapState({
      statuses: state => state.weeklyLog.statuses
    }),
    foldersOptions: {
      get() {
        return this.wantedFolders.map((folder) => {
          return {
            label: folder.alias + ' | ' + folder.name,
            code: folder.id,
            status: folder.status
          }
        })
      }
    }
  },
  methods: {
    ...mapMutations(['addFolder']),
    openSelect() {
      const input = this.$refs.selectProject.$el.querySelector('.vs__search')
      input.click()
      input.focus()
    },
    setSelected(value) {
      this.selectedValue = {
        code: '',
        label: 'Project'
      }

      this.addFolder({
        status: this.statuses.NEW,
        name: value.label,
        id: value.code,
        tasks: [
          {
            folder_name: value.label,
            folder_id: value.code,
            time_entries: [
              { duration: null, ids: [] },
              { duration: null, ids: [] },
              { duration: null, ids: [] },
              { duration: null, ids: [] },
              { duration: null, ids: [] },
              { duration: null, ids: [] },
              { duration: null, ids: [] }
            ]
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-select .archived {
  opacity: 0.6;
}
.project-select {
  width: 100%;
  height: 100%;

  position: relative;
  z-index: 2;

  img {
    position: absolute;
    top: 0;
    right: 30px;
    width: 12px;
    height: 100%;
    cursor: pointer;
    z-index: 1;
  }
}
</style>
