<template>
  <div class="cell">
    <input
      type="text"
      :class="{
        _last: isLast,
        _new: status === statuses.NEW,
        _disabled: disabled
      }"
      @change="handleInput"
      :value="formattedValue"
      placeholder="__h __m"
      ref="timeCell"
    />
  </div>
</template>

<script>
import { TimeConverter } from '../../services'
import { mapState } from 'vuex'
export default {
  name: 'TimeLogCell',

  props: {
    isLast: Boolean,
    value: Number,
    ids: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    status: String,
    taskId: String,
    order: {
      type: Number
    },
    at: Date
  },
  computed: {
    ...mapState({
      statuses: (state) => state.weeklyLog.statuses
    }),
    formattedValue() {
      return this.value ? TimeConverter.millisecondsToTime(this.value) : null
    }
  },
  methods: {
    handleInput(event) {
      console.log('handleInput', event)
      if (this.disabled) {
        return 0
      }
      const duration = TimeConverter.hoursToNumber(event.target.value)
      if (event.target.value.length === 0 || duration > 86400000) {
        this.$store.commit('updateTime', {
          taskId: this.taskId,
          position: this.order,
          duration: null
        })
      } else if (duration !== null) {
        this.$store.commit('updateTime', {
          taskId: this.taskId,
          position: this.order,
          duration: duration === 0 ? null : duration
        })
      } else {
        this.$store.commit('updateTime', {
          taskId: this.taskId,
          position: this.order,
          duration: false
        })
      }
      this.$refs.timeCell.value = this.formattedValue
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  background: #fff;
  border: 1px solid $color-secondary;
  border-right: unset;
  max-width: 100%;
  padding: 14px 10px;
  outline: none;
  height: 50px;
}

._last {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid $color-secondary;
}
._new {
  background-color: $active-cell;
}
._disabled {
  pointer-events: none;
}
.cell {
  height: 50px;
}
</style>
