<template>
  <div class="submit-popup">
    <div class="submit-popup__text">{{ text }}</div>
    <div class="submit-popup__actions">
      <button
        class="button button-secondary"
        @click="$emit('confirm', false)"
      >
        Back
      </button>
      <button class="button button-primary" @click="$emit('confirm', true)">
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupConfirmReport',

  props: {
    text: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-popup {
  &__text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  &__actions {
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 20px;
    }
  }
}
</style>
