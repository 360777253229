<template>
  <tr :class="{ _decorated: isLast, _first: isFirst }">
    <td colspan="2">
      <div class="double-row">
        <div class="project-cell" :title="formattedName">
          <template v-if="order === 0">
            <div
              class="project-cell__action project-cell__action_show"
              @click="clearFirstTask"
            ></div>
            <div class="project-cell__item">
              {{ formattedName }}
            </div>
          </template>
        </div>
        <task-cell :task="task" :order="order" :is-last="isLast" />
      </div>
    </td>

    <td v-for="(time, index) in task.time_entries" :key="time.id">
      <time-log-cell
        :value="time.duration"
        :ids="time.ids"
        :status="time.status"
        :order="index"
        :task-id="task.id"
        :at="time.at"
        :disabled="!task.id"
        :is-last="false"
      />
    </td>
    <td>
      <time-log-cell
        :value="task.id ? totalRow(task.id) : null"
        :ids="[]"
        :disabled="true"
        :is-last="true"
      />
    </td>
  </tr>
</template>

<script>
import TaskCell from './TaskCell'
import TimeLogCell from './TimeLogCell.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
export default {
  name: 'ProjectRow',
  components: { TimeLogCell, TaskCell },
  props: {
    task: { type: Object, required: true },
    isNew: Boolean,
    order: { type: Number, required: true },
    isLast: { type: Boolean, required: true },
    isFirst: { type: Boolean, required: true },
    folderStatus: String
  },
  computed: {
    ...mapGetters(['daysWeek', 'totalRow', 'allTasks']),
    ...mapState({
      statuses: (state) => state.weeklyLog.statuses
    }),
    formattedName() {
      return this.task.folder_name.split(' | ')[0]
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['clearTaskRow']),
    clearFirstTask() {
      this.clearTaskRow({
        taskId: this.task.id,
        allTasks: this.allTasks
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-cell {
  display: flex;
  justify-content: flex-start;

  &__item {
    flex-basis: 200px;
    background: #fff;
    border: 1px solid $color-secondary;
    max-width: 100%;
    padding: 14px 10px;
    outline: none;
    border-right: unset;
    border-radius: 6px 0 0 6px;
    font-weight: normal;
    height: 50px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &_new {
      background-color: $active-cell;
    }
  }
  &__action {
    visibility: hidden;
    // flex-basis: 30px;
    background: url('../../assets/icons/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-right: 4px;

    &_show {
      visibility: visible;
    }
  }
}
td {
  padding: 5px 0;
  &:first-child {
    padding: 5px 0 5px 5px;
    border-left: unset;
  }
  &:last-child {
    padding: 5px 5px 5px 0;
    border-right: unset;
  }
}
tr:first-child {
  td {
    padding-top: 20px;
  }
}
tr:last-child {
  td {
    padding-bottom: 20px;
  }
} // border-bottom: 1px solid $color-secondary;
.double-row {
  display: flex;
  align-items: flex-start;

  div:first-child {
    width: 30%;
  }
  div:last-child {
    width: 70%;
  }
}
._decorated {
  border-bottom: 1px solid $color-secondary;
  td {
    padding-bottom: 10px;
  }
}
._first {
  td {
    padding-top: 10px;
  }
}
</style>
