<template>
  <div class="day-picker">
    <div class="desc-label">{{ descLabel }}</div>
    <div class="picker">
      <date-pick
        ref="datepicker"
        v-model="value"
        @input="handleInput"
        :inputAttributes="{ readonly: true }"
        :format="'MM/DD/YYYY'"
        :isDateDisabled="isFutureDate"
      ></date-pick>
      <img src="../../assets/icons/calendar.svg" alt="" />
    </div>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick'
import { format, parse } from 'date-fns'
export default {
  name: 'DayPicker',
  components: {
    DatePick
  },
  props: {
    descLabel: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      value: format(new Date(), 'MM/dd/yyyy')
    }
  },
  mounted() {
    this.handleInput(this.value)
  },
  methods: {
    handleInput(e) {
      const currentDate = parse(e, 'MM/dd/yyyy', new Date())
      this.$emit('setDay', {
        string: this.value,
        date: currentDate
      })
    },
    isFutureDate(date) {
      return date > new Date()
    }
  }
}
</script>

<style lang="scss" scoped>
.picker {
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 20px;
    height: auto;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
.desc-label {
  margin-bottom: 5px;
  font-size: 14px;
  color: $text-secondary;
}
</style>
