<template>
  <div class="popup-switch">
    <div class="popup-switch__text">
      You have some data not submitted. Switch to another week anyway?
    </div>
    <div class="popup-switch__actions">
      <button class="button button-secondary" @click="$emit('switchWeekPopup', 'switch')">Switch without Submission</button>
      <button class="button button-primary" @click="$emit('switchWeekPopup', 'submit')">Submit and Switch</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupSwitchWeek'
}
</script>

<style lang="scss" scoped>
.popup-switch {
  &__text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    width: 320px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto 30px;
  }
  &__actions {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    button + button {
      margin-left: 30px;
    }
    button {
      white-space: nowrap;
    }
  }
}
</style>
