<template>
  <div class="popup-time-report">
    <div class="popup-time-report__item">
      <div class="header">Daily Report</div>
      <div class="controls">
        <day-picker desc-label="Choose day" @setDay="handleSetDay" />
        <button class="button button-primary" @click="generateDailyReport">
          Generate Daily Report
        </button>
      </div>
    </div>
    <div class="popup-time-report__item">
      <div class="header">Weekly Report</div>
      <div class="controls">
        <week-picker
          name="weekPicker"
          desc-label="Choose week"
          @setWeek="handleSetWeek"
        ></week-picker>
        <button class="button button-primary" @click="generateWeeklyReport">
          Generate Weekly Report
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WeekPicker from './controls/WeekPicker.vue'
import DayPicker from './controls/DayPicker.vue'
export default {
  name: 'PopupTimeReport',
  components: {
    WeekPicker,
    DayPicker
  },
  data() {
    return {
      day: null
    }
  },
  methods: {
    handleSetDay(value) {
      this.day = value.date
    },
    handleSetWeek(value) {
      this.week = value.range
    },
    generateDailyReport() {
      this.$emit('selectReportType', {
        type: 'daily',
        data: this.day
      })
    },
    generateWeeklyReport() {
      this.$emit('selectReportType', {
        type: 'weekly',
        data: this.week
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.popup-time-report {
  &__item + &__item {
    margin-top: 30px;
  }
}
.controls {
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 20px;
    width: 255px;
    max-width: 100%;
    justify-content: center;
  }
}
</style>
