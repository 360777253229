<template>
  <div class="report-cell" :class="cellCss">
    <input v-if="type === typeMap.base" type="text" :value="value" />
    <input
      v-if="type === typeMap.progress"
      type="text"
      v-model="selected"
      @change="handlePercentInput"
      ref="percentInput"
    />
    <v-select
      v-if="type === typeMap.status"
      :options="taskStatuses"
      v-model="selected"
      @input="handleStatusInput"
    />
    <v-select
      v-if="type === typeMap.general_status"
      :options="generalStatuses"
      v-model="selected"
      @input="handleGeneralStatusInput"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { PercentValidator } from '@/services/percent-cell-service'
export default {
  name: 'ReportCell',
  components: {
    vSelect
  },
  props: {
    initValue: {
      type: [Number, String, Object]
    },
    type: {
      required: true,
      type: String,
      default: 'input',
      validator(value) {
        return ['base', 'status', 'general-status', 'progress'].includes(value)
      }
    },
    order: {
      type: Number
    },
    isLast: {
      type: Boolean,
      default: false
    },
    taskStatuses: {
      type: Array,
      default() {
        return []
      }
    },
    generalStatuses: {
      type: Array,
      default() {
        return []
      }
    },
    taskId: {
      type: String
    },
    updated: {
      type: Boolean
    },
    value: {
      type: [String, Number, Object]
    }
  },
  data() {
    return {
      selected: this.value,
      typeMap: {
        base: 'base',
        status: 'status',
        general_status: 'general-status',
        progress: 'progress'
      }
    }
  },
  computed: {
    ...mapGetters(['statuses']),
    cellCss() {
      return {
        _first: this.order === 0,
        _last: this.isLast,
        _disabled: this.type === 'base',
        _updated: this.updated === true
      }
    }
  },
  methods: {
    handleStatusInput() {
      let status = ''
      if (this.selected.code === this.initValue.code) {
        status = this.statuses.INITIAL
      } else {
        status = this.statuses.NEW
      }
      this.$store.commit('updateReportStatus', {
        taskId: this.taskId,
        value: this.selected,
        status
      })
    },
    handleGeneralStatusInput() {
      let status = ''
      if (this.selected === this.initValue) {
        status = this.statuses.INITIAL
      } else {
        status = this.statuses.NEW
      }
      this.$store.commit('updateReportGeneralStatus', {
        taskId: this.taskId,
        value: this.selected,
        status
      })
    },
    handlePercentInput(event) {
      const input = PercentValidator.convertToPercent(event.target.value)
      let status = ''
      if (!input) {
        this.selected = this.initValue
        status = this.statuses.INITIAL
        return 0
      } else {
        this.selected = input
        status = this.statuses.NEW
      }
      if (input === this.initValue) {
        status = this.statuses.INITIAL
      } else {
        status = this.statuses.NEW
      }
      this.$store.commit('updateReportComplete', {
        taskId: this.taskId,
        value: this.selected,
        status
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.report-cell {
  input {
    border: 1px solid $color-secondary;
    border-right: none;
    width: 100%;
    padding: 15px 10px;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.2s ease-in-out;
  }
}
._first input {
  border-radius: 6px 0 0 6px;
}
._last input {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid $color-secondary;
}
._disabled {
  pointer-events: none;
}
._updated {
  input {
    background-color: $active-cell;
  }
}
</style>
