<template>
  <div class="reset-popup">
    <div class="reset-popup__text">Reset the changes?</div>
    <div class="reset-popup__actions">
      <button
        class="button button-secondary"
        @click="$emit('resetPopup', false)"
      >
        Cancel
      </button>
      <button class="button button-primary" @click="$emit('resetPopup', true)">
        Reset
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupLogReset'
}
</script>

<style lang="scss" scoped>
.reset-popup {
  &__text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  &__actions {
    display: flex;

    button + button {
      margin-left: 20px;
    }
  }
}
</style>
