<template>
  <div class="week-pagination">
    <div class="week-pagination__item" @click="setPrevWeek">Prev week</div>
    <div
      class="week-pagination__item"
      :class="{ _disabled: disabled }"
      @click="setNextWeek"
    >
      Next week
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { addWeeks, subWeeks, startOfDay } from 'date-fns'
export default {
  name: 'TheWeekPagination',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['week', 'timeEntryRequestBody']),
    disabled() {
      return (
        startOfDay(new Date()).getTime() <
        startOfDay(addWeeks(this.week.startDate, 1)).getTime()
      )
    }
  },
  methods: {
    ...mapMutations(['setWeek']),
    setPrevWeek() {
      const currentWeek = this.week
      currentWeek.startDate = subWeeks(currentWeek.startDate, 1)
      currentWeek.endDate = subWeeks(currentWeek.endDate, 1)
      if (this.timeEntryRequestBody.tasks.length) {
        this.$emit('exist-changes', currentWeek)
      } else {
        this.setWeek({ range: currentWeek, update: true })
      }
    },
    setNextWeek() {
      const currentWeek = this.week
      currentWeek.startDate = addWeeks(currentWeek.startDate, 1)
      currentWeek.endDate = addWeeks(currentWeek.endDate, 1)
      if (this.timeEntryRequestBody.tasks.length) {
        this.$emit('exist-changes', currentWeek)
      } else {
        this.setWeek({ range: currentWeek, update: true })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.week-pagination {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $text-secondary;
  div + div {
    margin-left: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:first-child::before,
    &:last-child::after {
      content: '';
      background: url('../assets/icons/arrow.svg');
      width: 1em;
      height: 1em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 0.5em;
      transition: $transition;
    }
    &:last-child::after {
      transform: rotate(180deg);
    }
    &:hover {
      color: darken($text-secondary, 10%);

      &::before {
        transform: translateX(-4px);
      }
      &::after {
        transform: translateX(4px) rotate(180deg);
      }
    }
  }
}
._disabled {
  pointer-events: none;
  color: lighten($text-secondary, 20%);
  cursor: default;
  &:last-child::after {
    display: none;
  }
}
</style>
