<template>
  <div>
    <time-log-navigation />
    <div class="weekly-log-page container">
      <div class="control-header">
        <date-picker @exist-changes="handleNotSubmittedEvent"></date-picker>
        <button class="button button-primary" @click="openCopyWeekPopup">
          Copy to Another Week
        </button>
      </div>
      <div class="weekly-log-tables">
        <table class="sticky-table">
          <thead>
          <tr>
            <td colspan="2"><project-select /></td>
            <td v-for="(day, index) in daysWeek" :key="index">
              {{ formatDate(day) }}
            </td>
            <td>Total</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td v-for="(time, index) in totalCol.week" :key="index">
              {{ formatDuration(time) }}
            </td>
            <td>{{ formatDuration(totalCol.total) }}</td>
          </tr>
          </thead>
        </table>
        <table class="table">
          <tbody>
          <template v-for="folder in folders">
            <project-row
              v-for="(task, index) in folder.tasks"
              :key="task.id"
              :task="task"
              :order="index"
              :is-last="index === folder.tasks.length - 1"
              :is-first="index === 0"
              :folder-status="folder.status"
            />
          </template>
          </tbody>
        </table>
      </div>
      <div class="log-actions">
        <div class="log-actions__item">
          <button class="button button-primary" @click="handleSubmitTimelog">
            Submit Timelog
          </button>
          <button class="button button-secondary" @click="handleResetTimelog">
            Reset
          </button>
        </div>
        <div class="log-actions__item">
          <the-week-pagination @exist-changes="handleNotSubmittedEvent" />
        </div>
        <div class="log-actions__item">
          <button
            class="button button-primary"
            @click="showTimeReportPopup = true"
          >
            Generate Report
          </button>
        </div>
      </div>
      <the-report v-if="showTimeReport" />
      <popup-template v-if="showSubmitPopup" @closePopup="handleClosePopup">
        <popup-log-submit @submitPopup="handleSubmitPopup" />
      </popup-template>
      <popup-template v-if="showResetPopup" @closePopup="handleClosePopup">
        <popup-log-reset @resetPopup="handleResetPopup" />
      </popup-template>
      <popup-template v-if="showCopyWeekPopup" @closePopup="handleClosePopup">
        <popup-copy-week @copyWeekPopup="handleCopyWeekPopup" />
      </popup-template>
      <popup-template v-if="showSwitchWeekPopup" @closePopup="handleClosePopup">
        <popup-switch-week @switchWeekPopup="handleSwitchWeekPopup" />
      </popup-template>
      <popup-template v-if="showTimeReportPopup" @closePopup="handleClosePopup">
        <popup-time-report @selectReportType="handleTimeReportPopup" />
      </popup-template>
    </div>
  </div>
</template>

<script>
import { TimeConverter } from '../services'
import { format, addDays } from 'date-fns'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '../components/controls/DatePicker.vue'
import ProjectRow from '../components/weekly-log/ProjectRow'
import ProjectSelect from '../components/weekly-log/ProjectSelect'
import PopupLogSubmit from '../components/PopupLogSubmit.vue'
import PopupLogReset from '../components/PopupLogReset.vue'
import PopupSwitchWeek from '../components/PopupSwitchWeek.vue'
import PopupCopyWeek from '../components/PopupCopyWeek.vue'
import PopupTimeReport from '../components/PopupTimeReport.vue'
import TheWeekPagination from '../components/TheWeekPagination.vue'
import TheReport from '../components/TheReport.vue'
import dayjs from 'dayjs'
import TimeLogNavigation from '../components/TimeLogNavigation.vue'
let utc = require('dayjs/plugin/utc')

dayjs.extend(utc)

export default {
  name: 'WeeklyLogPage',
  components: {
    TimeLogNavigation,
    DatePicker,
    ProjectRow,
    ProjectSelect,
    PopupLogSubmit,
    PopupLogReset,
    PopupCopyWeek,
    PopupSwitchWeek,
    TheWeekPagination,
    TheReport,
    PopupTimeReport
  },
  created() {
    this.unsubscribe = this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'setWeek' && mutation.payload.update) {
        console.log('--------------------------------', state.weeklyLog.week)
        await this.fetchTimesByWeek(state.weeklyLog.week)
        await this.fetchFolders()
      } else if (mutation.type === 'setWeek') {
        await this.fetchFolders()
      }
    })
  },
  computed: {
    ...mapGetters(['me', 'folders', 'daysWeek', 'totalCol', 'taskStatuses'])
  },
  data() {
    return {
      showSubmitPopup: false,
      showResetPopup: false,
      showCopyWeekPopup: false,
      showSwitchWeekPopup: false,
      showTimeReportPopup: false,

      bufferedRange: {},

      showTimeReport: false
    }
  },
  methods: {
    ...mapActions([
      'fetchTimesByWeek',
      'fetchFolders',
      'submitTimelog',
      'fetchTimesByReportObject',
      'fetchTaskStatuses'
    ]),
    ...mapMutations([
      'setViewLoader',
      'resetTimeCells',
      'setWeek',
      'setReportMode',
      'setReportDate'
    ]),
    formatDate(date) {
      return format(date, 'M/d/yyyy (E)')
    },
    formatDuration(time) {
      return TimeConverter.millisecondsToTime(time)
    },
    handleSubmitTimelog() {
      this.showSubmitPopup = true
    },
    handleResetTimelog() {
      this.showResetPopup = true
    },
    handleSubmitPopup(value) {
      value && this.submitTimelog()
      this.showSubmitPopup = false
    },
    handleResetPopup(value) {
      value && this.resetTimeCells()
      this.showResetPopup = false
    },
    handleClosePopup() {
      this.showSubmitPopup = false
      this.showResetPopup = false
      this.showCopyWeekPopup = false
      this.showSwitchWeekPopup = false
      this.showTimeReportPopup = false
    },
    handleCopyWeekPopup() {
      this.showCopyWeekPopup = false
    },
    async handleSwitchWeekPopup(value) {
      if (value === 'submit') {
        await this.submitTimelog()
      }
      this.setWeek({ range: this.bufferedRange, update: true })
      this.showSwitchWeekPopup = false
    },
    openCopyWeekPopup() {
      this.showCopyWeekPopup = true
    },
    handleNotSubmittedEvent(range) {
      this.showSwitchWeekPopup = true
      this.bufferedRange = range
    },
    async handleTimeReportPopup(reportObject) {
      this.showTimeReportPopup = false
      this.showTimeReport = true
      if (this.taskStatuses.length === 0) {
        await this.fetchTaskStatuses()
      }
      if (reportObject.type === 'daily') {
        await this.fetchTimesByReportObject({
          startDate: dayjs(reportObject.data).toISOString(),
          endDate: dayjs(addDays(reportObject.data, 1)).toISOString()
        })
      } else if (reportObject.type === 'weekly') {
        await this.fetchTimesByReportObject({
          startDate: reportObject.data.startDate.toISOString(),
          endDate: reportObject.data.endDate.toISOString()
        })
      }
      this.setReportDate(reportObject.data)
      this.setReportMode(reportObject.type)
    }
  },
  beforeDestroy() {
    this.unsubscribe()
  }
}
</script>
<style lang="scss">
.weekly-log-page {
  .table,
  .sticky-table {
    td:first-child {
      width: 33%;
    }
  }
}
</style>
<style lang="scss" scoped>
.weekly-log-page {
  padding-bottom: 60px;
}
.control-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.sticky-table {
  position: sticky;
  top: 0;
  background-color: #fff;
  margin-bottom: 0;
  z-index: 50;
}
table {
  width: 100%;
  margin: 0 auto;
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 50px;
}
thead td,
tbody td {
  max-width: 100%;
  word-wrap: break-word;
  padding: 10px;
  height: 58px;
  max-height: 100%;
  border: 1px solid $color-secondary;

  &:first-child {
    max-width: 100%;
    font-weight: bold;
    border-left: unset;
    width: 33%;
  }
  &:last-child {
    font-weight: bold;
    border-right: unset;
  }
}
thead {
  tr:nth-child(2) {
    font-weight: bold;
  }
}
tbody {
  background-color: $bg-secondary;
}
.log-actions {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item:first-child {
    margin-right: auto;
    button + button {
      margin-left: 25px;
    }
  }
  &__item:last-child {
    margin-left: auto;
  }
}

.links {
  display: flex;

  > *:not(:last-child) {
    margin-right: 20px;
  }
}
</style>
